define("discourse/plugins/stemaway-resume/discourse/helpers/clean-text", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cleanText = cleanText;
  _exports.default = void 0;
  function cleanText(_ref) {
    let [value] = _ref;
    return value.replace(/:[^:\s]*(?:::[^:\s]*)*:/g, '').trim();
  }
  var _default = _exports.default = (0, _helper.helper)(cleanText);
});