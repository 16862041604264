define("discourse/plugins/stemaway-resume/discourse/controllers/resume", ["exports", "@ember/controller", "@ember/object", "@ember/runloop", "discourse-common/utils/decorators", "@ember/application", "@ember/service", "discourse/plugins/stemaway-resume/discourse/components/modal/share-popup"], function (_exports, _controller, _object, _runloop, _decorators, _application, _service, _sharePopup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    modal: (0, _service.inject)(),
    editMode: false,
    toggleEditMode() {
      const editMode = this.get("editMode");
      if (editMode) {
        document.body.classList.remove("stemaway-resume-editing");
        this.set("editMode", false);
        (0, _runloop.later)(() => {
          this.send("refreshRoute");
        }, 500);
      } else {
        document.body.classList.add("stemaway-resume-editing");
        this.set("editMode", true);
      }
    },
    showShareModal() {
      if (!this.user) {
        console.error("User is undefined in showShareModal");
        return;
      }
      this.modal.show(_sharePopup.default, {
        model: {
          user: this.user
        }
      });
    },
    canEditResume(user) {
      if (!this.currentUser) {
        return false;
      } else if (this.currentUser.username === user.username) {
        return true;
      } else if (this.currentUser.admin) {
        return true;
      } else {
        return false;
      }
    },
    resumeHidden(displayAllowed, owner) {
      return !displayAllowed && !owner;
    }
  }, [["method", "toggleEditMode", [_object.action]], ["method", "showShareModal", [_object.action]], ["method", "canEditResume", [(0, _decorators.default)("user")]], ["method", "resumeHidden", [(0, _decorators.default)("display_allowed", "owner")]]]));
});