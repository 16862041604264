define("discourse/plugins/stemaway-resume/discourse/components/modal/share-popup", ["exports", "@ember/component", "@ember/service", "discourse-common/lib/get-url", "discourse/lib/sharing", "@ember/template-factory"], function (_exports, _component, _service, _getUrl, _sharing, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal @closeModal={{@closeModal}} class="share-popup-modal">
    <:body>
      <form>
        <div class="input-group invite-link">
          <label for="invite-link">
            {{i18n "stemaway_resume.share.instructions"}}
          </label>
          <div class="link-share-container">
            <input
              id="invite-link"
              class="invite-link"
              value={{this.resumeUrl}}
              readonly
            />
            <CopyButton @selector="#invite-link" />
          </div>
        </div>
        <div class="link-share-actions">
          <div class="sources">
            {{#each this.sources as |source|}}
              {{share-source source=source title=this.resumeTitle action=(fn this.share source)}}
            {{/each}}
          </div>
        </div>
      </form>
    </:body>
  </DModal>
  
  */
  {
    "id": "te2P1Z2E",
    "block": "[[[8,[39,0],[[24,0,\"share-popup-modal\"]],[[\"@closeModal\"],[[30,1]]],[[\"body\"],[[[[1,\"\\n    \"],[10,\"form\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"input-group invite-link\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,\"for\",\"invite-link\"],[12],[1,\"\\n          \"],[1,[28,[35,5],[\"stemaway_resume.share.instructions\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"link-share-container\"],[12],[1,\"\\n          \"],[10,\"input\"],[14,1,\"invite-link\"],[14,0,\"invite-link\"],[15,2,[30,0,[\"resumeUrl\"]]],[14,\"readonly\",\"\"],[12],[13],[1,\"\\n          \"],[8,[39,7],null,[[\"@selector\"],[\"#invite-link\"]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"link-share-actions\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"sources\"],[12],[1,\"\\n\"],[42,[28,[37,9],[[28,[37,9],[[30,0,[\"sources\"]]],null]],null],null,[[[1,\"            \"],[1,[28,[35,10],null,[[\"source\",\"title\",\"action\"],[[30,2],[30,0,[\"resumeTitle\"]],[28,[37,11],[[30,0,[\"share\"]],[30,2]],null]]]]],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[\"@closeModal\",\"source\"],false,[\"d-modal\",\":body\",\"form\",\"div\",\"label\",\"i18n\",\"input\",\"copy-button\",\"each\",\"-track-array\",\"share-source\",\"fn\"]]",
    "moduleName": "discourse/plugins/stemaway-resume/discourse/components/modal/share-popup.hbs",
    "isStrictMode": false
  });
  class SharePopupModal extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get resumeUrl() {
      const user = this.model?.user;
      if (!user || !user.username) return "";
      const path = `/u/${user.username}/resume`;
      const url = (0, _getUrl.getAbsoluteURL)(path);
      return url;
    }
    get resumeTitle() {
      const user = this.model?.user;
      return user ? `${user.name}'s Resume` : null;
    }
    get sources() {
      return _sharing.default.activeSources(this.siteSettings.share_links);
    }
    save = () => {
      this.args.closeModal();
    };
    share = source => {
      _sharing.default.shareSource(source, {
        url: this.resumeUrl,
        title: this.resumeTitle
      });
    };
  }
  _exports.default = SharePopupModal;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SharePopupModal);
});