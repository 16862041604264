define("discourse/plugins/stemaway-resume/discourse/components/resume-user-card", ["exports", "@ember/component", "discourse-common/utils/decorators", "@ember/object", "@ember/runloop", "discourse/lib/url", "@ember/service"], function (_exports, _component, _decorators, _object, _runloop, _url, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "section",
    classNames: ["resume-user-card"],
    elementId: "resume-section-0",
    currentUser: (0, _service.inject)(),
    uploadingPdf: false,
    pdfUploaded: false,
    bannerImage(user) {
      const defaultBannerImage = "/plugins/stemaway-resume/images/resume/default-banner.png";
      if (user) {
        if (user.banner) {
          return user.banner;
        } else {
          return defaultBannerImage;
        }
      } else {
        return defaultBannerImage;
      }
    },
    resumeName(user) {
      if (user) {
        if (user.name) {
          return user.name;
        } else {
          return user.username;
        }
      }
    },
    editAbout() {
      let post = this.get("about");
      _url.default.routeTo(`/t/${post.topic_id}`);
      (0, _runloop.next)(() => {
        setTimeout(() => {
          let editButton = document.querySelector("button.widget-button.btn-flat.edit.no-text.btn-icon");
          if (editButton) {
            editButton.click();
          }
          window.history.back();
        }, 700);
      });
    },
    createAbout() {
      const user = this.get("user");
      const name = user.name ? user.name : user.username;
      const bio = "Insert%20content";
      const route = `/new-topic?title=About%20${name}&body=${bio}&category=resume/1-click-resume/about&tags=resume_a`;
      _url.default.routeTo(route);
    },
    uploadPdf() {
      this.set('uploadingPdf', false);
      this.set('pdfUploaded', false);
      let input = document.createElement("input");
      input.type = "file";
      input.accept = ".pdf";
      input.onchange = e => {
        let file = e.target.files[0];
        if (!file) {
          return;
        }
        this.set('uploadingPdf', true);
        let formData = new FormData();
        formData.append("file", file);
        let username = this.get("user.username") || this.get("currentUser.username");
        if (!username) {
          console.error("Username not available.");
          this.set('uploadingPdf', false);
          return;
        }
        let csrfMeta = document.querySelector('meta[name="csrf-token"]');
        if (!csrfMeta) {
          console.error("CSRF token not found.");
          this.set('uploadingPdf', false);
          return;
        }
        let csrfToken = csrfMeta.getAttribute('content');
        let url = `/u/${username}/resume/upload_pdf`;
        fetch(url, {
          method: "POST",
          body: formData,
          credentials: "same-origin",
          headers: {
            "X-CSRF-Token": csrfToken,
            "Accept": "application/json"
          }
        }).then(response => {
          let contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(data => {
              if (!response.ok) {
                throw new Error("Error: " + JSON.stringify(data));
              }
              return data;
            });
          } else {
            return response.text().then(text => {
              throw new Error("Response is not JSON: " + text);
            });
          }
        }).then(data => {
          this.set('uploadingPdf', false);
          this.set('pdfUploaded', true);
          window.location.reload();
        }).catch(error => {
          console.error("Error uploading PDF:", error);
          this.set('uploadingPdf', false);
        });
      };
      input.click();
    }
  }, [["method", "bannerImage", [(0, _decorators.default)("user")]], ["method", "resumeName", [(0, _decorators.default)("user")]], ["method", "editAbout", [_object.action]], ["method", "createAbout", [_object.action]], ["method", "uploadPdf", [_object.action]]]));
});