define("discourse/plugins/stemaway-resume/discourse/components/resume-sidebar", ["exports", "@ember/component", "@ember/object", "discourse/lib/plugin-api"], function (_exports, _component, _object, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    sidebarState: "resume-sidebar-expanded",
    classNames: ["resume-sidebar-container"],
    sidebarExpanded: true,
    toggleSidebar() {
      this.sidebarExpanded = !this.sidebarExpanded;
      if (this.sidebarExpanded) {
        this.set("sidebarState", "resume-sidebar-expanded");
        document.body.classList.remove("stemaway-resume-sidebar-minimized");
      } else {
        document.body.classList.add("stemaway-resume-sidebar-minimized");
        this.set("sidebarState", "resume-sidebar-collapsed");
      }
    },
    didInsertElement() {
      this._super(...arguments);
      const user = this.get("user");
      (0, _pluginApi.withPluginApi)("0.8.42", api => {
        api.onPageChange(url => {
          if (url === `/u/${user.username}/resume`) {
            window.onscroll = () => {
              const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                  const id = entry.target.getAttribute("id");
                  const item = document.querySelector(`.resume-sidebar-navigation-items li a[href="#${id}"]`);
                  if (item !== null && entry.intersectionRatio > 0) {
                    item.parentElement.classList.add("active-nav-item");
                  } else {
                    if (item !== null) {
                      item.parentElement.classList.remove("active-nav-item");
                    }
                  }
                });
              });
              document.querySelectorAll(".resume-all-content-sections section[id]").forEach(section => {
                observer.observe(section);
              });
            };
          }
        });
      });
    }
  }, [["method", "toggleSidebar", [_object.action]]]));
});