define("discourse/plugins/stemaway-resume/discourse/components/resume-section", ["exports", "@ember/component", "@ember/runloop", "@ember/object", "@ember/object/computed", "discourse-common/utils/decorators", "@ember/service", "discourse/lib/url"], function (_exports, _component, _runloop, _object, _computed, _decorators, _service, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    router: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    topic: (0, _computed.reads)("model.topic"),
    sectionExpanded: true,
    sectionState: "resume-section-expanded",
    isExperience: (0, _computed.equal)("section.title", "Full Resume"),
    isBadges: (0, _computed.equal)("section.title", "Badges"),
    isVirtualInternships: (0, _computed.equal)("section.title", "STEM-Away Internships"),
    isEndorsements: (0, _computed.equal)("section.title", "Endorsements"),
    isTopSkills: (0, _computed.equal)("section.title", "Top Skills"),
    displaySection(section) {
      if (!section || !section.title) {
        return false;
      }
      const topics = section.topics || [];
      const isVirtualInternships = this.get("isVirtualInternships");
      const isTopSkills = this.get("isTopSkills");
      const endorsements = this.get("endorsements") || [];
      const specialBadges = this.get("specialBadges") || [];
      const assessments = this.get("assessments") || [];
      const topSkills = this.get("topSkills") || [];
      if (topics.length === 0 && !section.unique_section) {
        return false;
      }
      if (section.title === "About" && topics.length === 0) {
        return false;
      }
      if (isVirtualInternships) {
        if (topics.length < 1 && endorsements.length === 0 && specialBadges.length === 0 && assessments.length === 0) {
          return false;
        }
      }
      if (isTopSkills && topSkills.length < 1) {
        return false;
      }
      return true;
    },
    specialBadges(badges) {
      const internshipBadgeId = this.siteSettings.resume_internship_badge_id;
      const mentorChainsBadgeId = this.siteSettings.resume_mentor_chains_badge_id;
      const specialBadges = [];
      if (!badges) {
        return;
      }
      badges.forEach(badge => {
        if (badge.grouping_id === internshipBadgeId || badge.grouping_id === mentorChainsBadgeId) {
          specialBadges.push(badge);
        }
      });
      specialBadges.sort((a, b) => a.priority - b.priority);
      return specialBadges.slice(0, 5);
    },
    toggleSection() {
      this.sectionExpanded = !this.sectionExpanded;
      if (this.sectionExpanded) {
        this.set("sectionState", "resume-section-expanded");
      } else {
        this.set("sectionState", "resume-section-collapsed");
      }
    },
    editExperience() {
      let post = this.get("experience_post");
      _url.default.routeTo(`/t/${post.topic_id}`);
      (0, _runloop.next)(() => {
        setTimeout(() => {
          let editButton = document.querySelector("button.widget-button.btn-flat.edit.no-text.btn-icon");
          if (editButton) {
            editButton.click();
          }
          // window.history.back();
        }, 700);
      });
    },
    createTopic() {
      const user = this.get("user");
      const tags = this.get("section").tags;
      const category = "resume/1-click-resume-enhance/highlights";
      const name = user.name ? user.name : user.username;
      const bio = "Insert%20content";
      const route = `/new-topic?title=Skills%20about%20${name}&body=${bio}&category=${category}&tags=${tags}`;
      _url.default.routeTo(route);
    },
    addBackground() {
      const user = this.get("user");
      const tags = this.get("section").tags;
      const category = "resume/1-click-resume/background";
      const name = user.name ? user.name : user.username;
      const bio = "Insert%20content";
      const route = `/new-topic?title=Skills%20about%20${name}&body=${bio}&category=${category}&tags=${tags}`;
      _url.default.routeTo(route);
    },
    didInsertElement() {
      this._super(...arguments);
    }
  }, [["method", "displaySection", [(0, _decorators.default)("section")]], ["method", "specialBadges", [(0, _decorators.default)("badges")]], ["method", "toggleSection", [_object.action]], ["method", "editExperience", [_object.action]], ["method", "createTopic", [_object.action]], ["method", "addBackground", [_object.action]]]));
});